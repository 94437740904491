<template>
  <section>
    <base-card>
      <h2>Coming Soon(ish)...</h2>
    </base-card>
  </section>
</template>

<script>
// export default {
//   props: ['id'],
//   data() {
//     return {
//       selectedCoach: null,
//     };
//   },
//   computed: {
//     fullName() {
//       return this.selectedCoach.firstName + ' ' + this.selectedCoach.lastName;
//     },
//     areas() {
//       return this.selectedCoach.areas;
//     },
//     rate() {
//       return this.selectedCoach.hourlyRate;
//     },
//     description() {
//       return this.selectedCoach.description;
//     },
//     contactLink() {
//       return this.$route.path + '/' + this.id + '/contact';
//     },
//   },
//   created() {
//     this.selectedCoach = this.$store.getters['coaches/coaches'].find(
//       (coach) => coach.id === this.id
//     );
//   },
// };
</script>
