import { createApp } from "vue";
// import { firestorePlugin } from 'vuefire/dist/packages/vuefire/src';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaseCard from "./components/ui/BaseCard.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseBadge from "./components/ui/BaseBadge.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";

// createApp(App)
//   .use(store)
//   .use(router)
//   .use(firestorePlugin)
//   .mount("#app");

const app = createApp(App);

app.use(store);
app.use(router);
app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-badge', BaseBadge);
app.component('base-Spinner', BaseSpinner);

app.mount("#app");
