
import { defineComponent } from "vue";
import TheHeader from "./components/layout/TheHeader.vue";
import ComingSoon from "./views/ComingSoon.vue";

export default defineComponent({
  components: {
    TheHeader,
    ComingSoon,
  },
});
